import React from "react";

function ThankYouPage() {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
            <div className="bg-white shadow-lg rounded-2xl p-8 max-w-lg text-center">
                <h1 className="text-3xl font-bold text-blue-600">Thank You!</h1>
                <p className="text-gray-700 mt-4">Your application has been successfully submitted.</p>
                <p className="text-gray-600 mt-2">Our admissions team will review your application and get back to you soon.</p>
                <div className="mt-6">
                    <a href="/" className="px-6 py-3 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700 transition">
                        Back to Home
                    </a>
                </div>
                {/* <div className="mt-4 text-gray-500 text-sm">
                    Need help? <a href="/contact" className="text-blue-500 hover:underline">Contact us</a>
                </div> */}
            </div>
        </div>
    );
};

export default ThankYouPage;
